import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // - 測試區
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/pages/TestPage.vue')
  },
  {
    path: '/playground',
    name: 'playground',
    component: () => import('@/components/demo/PlaygroundHome.vue')
  },
  {
    path: '/playground/parent-child-Parent',
    component: () =>
      import('@/components/demo/parent-child/PlaygroundParent.vue'),
    children: [
      {
        path: '/playground/parent-child',
        name: 'parent-child',
        component: () =>
          import('@/components/demo/parent-child/PlaygroundChild.vue')
      }
    ]
  },
  // - 應徵者表單
  {
    path: '/applicant_form',
    name: 'appl_form',
    component: () => import('@/layout/ApplFormLayout.vue'),
    children: [
      {
        path: '/applicant_form_tw',
        name: 'appl_form_tw',
        component: () => import('@/pages/appl_form/ApplFormTw.vue')
      },
      {
        path: '/applicant_form_en',
        name: 'appl_form_en',
        component: () => import('@/pages/appl_form/ApplFormEn.vue')
      },
      {
        path: '/applicant_form_done_tw',
        name: 'appl_form_done_tw',
        component: () => import('@/pages/appl_form/ApplFormDoneTw.vue')
      },
      {
        path: '/applicant_form_done_en',
        name: 'appl_form_done_en',
        component: () => import('@/pages/appl_form/ApplFormDoneEn.vue')
      }
    ]
  },
  // - 登入頁面
  {
    path: '/login',
    component: () => import('@/layout/LoginPageLayout.vue'),
    children: [
      {
        path: '',
        name: 'login_page',
        component: () => import('@/pages/LoginPage.vue')
      }
    ]
  },
  // - 管理系統
  {
    path: '/dashboard',
    component: () => import('@/layout/DashFrame.vue'),
    children: [
      {
        path: 'signature_task',
        name: 'sign_task',
        component: () => import('@/pages/main/SignTask.vue')
      },
      {
        path: 'company_data',
        name: 'co_data',
        component: () => import('@/pages/main/basic_settings/CoData.vue')
      },
      {
        path: 'access_set_up',
        name: 'acs_setup',
        component: () => import('@/pages/main/basic_settings/AcsSetup.vue')
      },
      {
        path: 'department_set_up',
        name: 'dept_setup',
        component: () => import('@/pages/main/basic_settings/DeptSetup.vue')
      },
      {
        path: 'position_set_up',
        name: 'pos_setup',
        component: () => import('@/pages/main/basic_settings/PosSetup.vue')
      },
      {
        path: 'contract_type_set_up',
        name: 'contr_type_setup',
        component: () =>
          import('@/pages/main/basic_settings/ContrTypeSetup.vue')
      },
      {
        path: 'nation_code_set_up',
        name: 'nat_setup',
        component: () => import('@/pages/main/basic_settings/NatSetup.vue')
      },
      {
        path: 'project_and_grant_set_up',
        name: 'proj_grant_setup',
        component: () =>
          import('@/pages/main/basic_settings/ProjAndGrantSetup.vue')
      },
      {
        path: 'holiday_set_up',
        name: 'holiday_setup',
        component: () => import('@/pages/main/basic_settings/HolidaySetup.vue')
      },
      {
        path: 'employee_data',
        name: 'emp_data',
        component: () => import('@/pages/main/hr_manage/EmpData.vue')
      },
      {
        path: 'transfer_records',
        name: 'trf_rec',
        component: () => import('@/pages/main/hr_manage/TrfRec.vue')
      },
      {
        path: 'employee_contract',
        name: 'emp_contr',
        component: () => import('@/pages/main/hr_manage/EmpContract.vue')
      },
      {
        path: 'contract_template',
        name: 'contr_tmplt',
        component: () => import('@/pages/main/hr_manage/ContrTmplt.vue')
      },
      {
        path: 'employee_document',
        name: 'emp_docs',
        component: () => import('@/pages/main/hr_manage/EmpDocs.vue')
      },
      {
        path: 'attendance_overview',
        name: 'attend_ov',
        component: () => import('@/pages/main/attend_manage/AttendOverview.vue')
      },
      {
        path: 'shift_management',
        name: 'shift_manage',
        component: () => import('@/pages/main/attend_manage/ShiftManage.vue')
      },
      {
        path: 'annual_and_compensatory_management',
        name: 'annual_comp_manage',
        component: () =>
          import('@/pages/main/attend_manage/AnnualCompManage.vue')
      },
      {
        path: 'annual_and_compensatory_transactions',
        name: 'annual_comp_trans',
        component: () =>
          import('@/pages/main/attend_manage/AnnualCompTrans.vue')
      },
      {
        path: 'salary_report',
        name: 'SalaryReport',
        component: () => import('@/pages/main/remun_manage/SalaryReport.vue')
      },
      {
        path: 'salary_adjustment',
        name: 'SalaryAdjust',
        component: () => import('@/pages/main/remun_manage/SalaryAdjust.vue')
      },
      {
        path: 'project_and_grant_management',
        name: 'proj_grant',
        component: () =>
          import('@/pages/main/remun_manage/ProjAndGrantCase.vue')
      },
      {
        path: 'performance_evaluation',
        name: 'perf_eval',
        component: () => import('@/pages/main/perf_eval/PerfEval.vue')
      },
      {
        path: 'employee_data_statement',
        name: 'emp_data_stmt',
        component: () =>
          import('@/pages/main/stmt_manage/class_hr/EmpDataStmt.vue')
      },
      {
        path: 'transfer_records_statement',
        name: 'trf_rec_stmt',
        component: () =>
          import('@/pages/main/stmt_manage/class_hr/TrfRecStmt.vue')
      },
      {
        path: 'onboarding_and_resign_statement',
        name: 'ob_resign_stmt',
        component: () =>
          import('@/pages/main/stmt_manage/class_hr/ObResignStmt.vue')
      },
      {
        path: 'employee_application_record',
        name: 'EmpApplyRecord',
        component: () => import('@/pages/main/EmpApplyRecord.vue')
      },
      {
        path: 'scales_of_salary',
        name: 'sas_sal',
        component: () =>
          import('@/pages/main/stmt_manage/class_remun/SasSal.vue')
      },
      {
        path: 'applicant_data_repository',
        name: 'appl_data_repo',
        component: () => import('@/pages/main/oths_repo/ApplDataRepo.vue')
      }
    ]
  }
  //
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
