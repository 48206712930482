import axios from 'axios';
import pinia from '@/stores';
import { hintReportError } from '@/scripts/QuasarNotify';
import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import App from '@/App.vue';

const app = createApp(App);
app.use(pinia);

import { useLoginTimeout } from '@/stores/login-timeout.js';
import { useReportError } from '@/stores/report-error';
import { useUserStore } from '@/stores/UserStore';

const dialog = useLoginTimeout();
const reportError = useReportError();
const user = useUserStore();

import { version } from '@/../package.json';

const api = axios.create({
  baseURL: '/api',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

const expectedError = (error) => {
  const msg = [
    'EmployeeNotFound',
    'The employee has already left the company',
    'Invalid EmpId',
    'PermissionDenied'
  ];

  return msg.includes(error.response.data?.msg || error.message || false);
};

// 添加axios攔截error給Sentry
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // - 請求已經發出，得到非 2xx
    // > 如果error 符合 expectedError，則不顯示錯誤訊息
    if (!expectedError(error)) {
      if (error.request.status === 401) {
        if (error.config.url !== '/auth/login') {
          dialog.statusDialog(true);
        }
      } else {
        // > 忽略 401 錯誤，因為只代表user未登入
        hintReportError();

        const parsedError = `
          Version: ${version} \n
          EmpID: ${user.empId} \n
          Method: ${error.config.method} \n
          URL: ${error.request.responseURL} \n
          Payload: ${error.config.data} \n
          Error: ${JSON.stringify(error.response.data || error.message)}
        `;

        Sentry.setTag('empId', user.empId);
        Sentry.setTag('empName', user.empName);
        Sentry.setUser({ id: user.empId });
        Sentry.withScope((scope) => {
          const strError = error.response
            ? JSON.stringify(error.response.data)
            : JSON.stringify(error.message);

          Sentry.addBreadcrumb({
            timestamp: new Date().toISOString(),
            category: 'xhr',
            level: 'debug',
            message: strError
          });

          Sentry.addBreadcrumb({
            timestamp: new Date().toISOString(),
            category: 'xhr',
            level: 'debug',
            message: parsedError
          });
          scope.captureException(error);
        });

        reportError.errorMsg = parsedError;
      }
    }

    return Promise.reject(error.response.data || error.message);
  }
);

export default api;
